import { websiteLocales } from '@lib/routes'
import { PromoBarProps } from '@modules/shared/promo-bar'
import axios from 'axios'
import { getSanityClient } from './sanity'

/*  ------------------------------ */
/*  Global Site Queries
/*  ------------------------------ */

const isActiveProduct =
  'isDraft != true && visible == true && wasDeleted != true'

// Construct our "menu items" GROQ
const link = (locale) => {
  return `
  _key,
  _type,
  "title": ${getLocaleQuery('titleLocale', locale, 'title')},
  url,
  "page": page->{"type": _type, "slug": slug.current}
`
}

export const pageSeo = (locale) => {
  return `
  "metaTitle": ${getLocaleQuery('metaTitle', locale)},
  "metaDesc": ${getLocaleQuery('metaDesc', locale)},
  shareGraphic,
  noIndex
`
}

export type SupportedLocale = 'ja' | 'en'

const productSummary = (locale: SupportedLocale) => {
  return `
  {
    "slug": slug.current,
    "id": productID,
    "title": ${getLocaleQuery('titleLocale', locale, 'title')},
    price,
    brand->{
      name,
      "collectionPageSlug": collectionPage->slug.current
    },
    comparePrice,
    "photos": {
      "main": galleryPhotos[]{
        forOption,
        photos[visible == true]{
          ${imageMeta(locale)}
        }
      },
      "listing": listingPhotos[]{
        forOption,
        "default": listingPhoto{
          ${imageMeta(locale)}
        },
        "hover": listingPhotoHover{
          ${imageMeta(locale)}
        }
      },
    },
    visible,
    inStock,
    lowStock,
    surfaceOption,
    vendor,
    productCategory,
    options[]{
      name,
      position,
      values[]
    },
    optionSettings[]{
      forOption,
      color
    },
    "variants": *[_type == "productVariant" && productID == ^.productID && wasDeleted != true && isDraft != true]{
      "id": variantID,
      title,
      price,
      comparePrice,
      inStock,
      sku,
      lowStock,
      options[]{
        name,
        position,
        value
      }
    }
  }
`
}

// Construct our "image meta" GROQ
export const imageMeta = (locale) => `
  "alt": ${getLocaleQuery('alt', locale)},
  asset,
  crop,
  hotspot,
  spin,
  "id": asset->assetId,
  "type": asset->mimeType,
  "aspectRatio": asset->metadata.dimensions.aspectRatio,
  "lqip": asset->metadata.lqip
`

export const imageMetaWithContent = (locale) => `
  ${imageMeta(locale)},
  "content": ${getLocaleSimpleContentBlockQuery('content', locale)},
  link{
    ${link(locale)}
  }
`
export const imageMetaWithSizeControls = (locale) => `
  ${imageMeta(locale)},
  "content": ${getLocaleSimpleContentBlockQuery('content', locale)},
  size,
  link{
    ${link(locale)}
  }
`

// Construct our "portable text content" GROQ
export const ptContent = (locale) => `
  ...,
  markDefs[]{
    ...,
    _type == "link" => {
      "url": @.url,
      "isButton": @.isButton,
      "styles": @.styles{style, isLarge, isBlock},
      "page":@.page->{"type": _type, "slug": slug.current}
    }
  },
  _type == "figure" => {
    ${imageMetaWithSizeControls(locale)}
  }
`
// Construct our "portable text content" GROQ
export const simplePtContent = `
  ...,
  markDefs[]{
    ...,
    _type == "link" => {
      "url": @.url,
      "isButton": @.isButton,
      "styles": @.styles{style, isLarge, isBlock},
      "page":@.page->{"type": _type, "slug": slug.current}
    }
  }
`
const getLocaleQuery = (key, currentLocale, fallback?) => {
  const orderedLocales = websiteLocales
    .filter((locale) => locale !== currentLocale)
    .map((locale) => `${key}.${locale}`)

  const current = currentLocale ? `${key}.${currentLocale}` : undefined

  const all = [current, ...orderedLocales, fallback].filter((a) => a).join(',')

  return `coalesce(${all})`
}

// Construct our "product" GROQ
const product = (locale) => {
  return `
  {
    "slug": slug.current,
    "id": productID,
    "title": ${getLocaleQuery('titleLocale', locale, 'title')},
    price,
    candleProperties {
      brightness,
      burnTime,
      "dimensions": ${getLocaleQuery('dimensionsLocale', locale, 'dimensions')},
      "inclusions": ${getLocaleQuery('inclusions', locale)}
    },
    sku,
    brand->{
      name,
      "collectionPageSlug": collectionPage->slug.current
    },
    comparePrice,
    "shortDescription": ${getLocaleQuery(
      'shortDescriptionLocale',
      locale,
      'shortDescription'
    )},
    "description": ${getLocaleQuery(
      'descriptionLocale',
      locale,
      'description'
    )},
    seo{
      ${pageSeo(locale)}
    },
    "dimensions": ${getLocaleQuery('dimensionsLocale', locale)},
    "materials": ${getLocaleQuery('materialsLocale', locale)},
    "manufacturedIn": ${getLocaleQuery('manufacturedInLocale', locale)},
    "photos": {
      "main": galleryPhotos[]{
        forOption,
        photos[visible == true]{
          ${imageMeta(locale)}
        }
      },
      "listing": listingPhotos[]{
        forOption,
        "default": listingPhoto{
          ${imageMeta(locale)}
        },
        "hover": listingPhotoHover{
          ${imageMeta(locale)}
        }
      },
    },
    visible,
    isDraft,
    inStock,
    lowStock,
    useGallery,
    surfaceOption,
    vendor,
    productCategory,
    options[]{
      name,
      position,
      values[]
    },
    optionSettings[]{
      forOption,
      color
    },
    "variants": *[_type == "productVariant" && productID == ^.productID && wasDeleted != true && isDraft != true]{
      "id": variantID,
      title,
      price,
      comparePrice,
      inStock,
      sku,
      lowStock,
      options[]{
        name,
        position,
        value
      }
    },
    "klaviyoAccountID": *[_type == "generalSettings"][0].klaviyoAccountID
  }
`
}

const getLocaleContentBlockQuery = (key, currentLocale, fallback?) => {
  const orderedLocales = websiteLocales
    .filter((locale) => locale !== currentLocale)
    .map(
      (locale) => `
      ${key}.${locale}[]{
       ${ptContent(locale)}
      }`
    )

  const current = currentLocale
    ? `${key}.${currentLocale}[]{
        ${ptContent(currentLocale)}
      }`
    : undefined

  const all = [
    current,
    ...orderedLocales,
    fallback
      ? `${fallback}[]{
      ${ptContent(currentLocale)}
    }`
      : undefined,
  ]
    .filter((a) => a)
    .join(',')

  return `coalesce(${all})`
}

const getLocaleSimpleContentBlockQuery = (key, currentLocale, fallback?) => {
  const orderedLocales = websiteLocales
    .filter((locale) => locale !== currentLocale)
    .map(
      (locale) => `
      ${key}.${locale}[]{
       ${simplePtContent}
      }`
    )

  const current = currentLocale
    ? `${key}.${currentLocale}[]{
        ${simplePtContent}
      }`
    : undefined

  const all = [
    current,
    ...orderedLocales,
    fallback
      ? `${fallback}[]{
      ${simplePtContent}
    }`
      : undefined,
  ]
    .filter((a) => a)
    .join(',')

  return `coalesce(${all})`
}

// Construct our "blocks" GROQ
export const blocks = (locale) => {
  return `
  _type == 'freeform' => {
    _type,
    _key,
    "content": ${getLocaleContentBlockQuery(
      'contentLocale',
      locale,
      'content'
    )},
    textAlign,
    maxWidth
  },
  _type == 'accordions' => {
    _type,
    _key,
    items[]{
      "id": _key,
      title,
      "content": ${getLocaleQuery('contentLocale', locale, 'content')}
    }
  },
  _type == 'productCard' => {
    _type,
    _key,
    product->${product(locale)}
  },
  _type == 'productHeroCard' => {
    _type,
    _key,
    product->${product(locale)}
  },
  _type == 'photo' => {
    _type,
    _key,
    photo{
      ${imageMeta(locale)}
    }
  },
`
}

// Construct our content "modules" GROQ
export const modules = (locale) => {
  return `
  _type == 'grid' => {
    _type,
    _key,
    size,
    hidden,
    columns[]{
      sizes[]{
        breakpoint,
        width,
        justify,
        align,
        start
      },
      blocks[]{
        ${blocks(locale)}
      }
    }
  },
  _type == 'hero' => {
    _type,
    _key,
    "content": ${getLocaleContentBlockQuery(
      'contentLocale',
      locale,
      'content'
    )},
    bgType,
    photos{
      ...,
      mobilePhoto{
        ${imageMeta(locale)}
      },
      desktopPhoto{
        ${imageMeta(locale)}
      }
    },
    video{
      id,
      title
    }
  },
  _type == 'marquee' => {
    _type,
    _key,
    items[]{
      _type == 'simple' => {
        _type,
        "text": ${getLocaleContentBlockQuery('text', locale)},
      },
      _type == 'photo' => {
        _type,
        "photo": {
          ${imageMeta(locale)}
        }
      },
      _type == 'product' => {
        _type,
        _id,
        "product": *[_type == "product" && _id == ^._ref && ${isActiveProduct}][0]${product(
    locale
  )}
      }
    },
    speed,
    reverse,
    pausable
  },
  _type == 'mapSection' => {
    _type,
    _key,
    location,
    "content": ${getLocaleContentBlockQuery('content', locale)},
  },
  _type == 'imageGrid' => {
    _type,
    _key,
    photos[]{
      ${imageMetaWithContent(locale)}
    },
    "title": ${getLocaleQuery('title', locale)},
    hideTitle,
    "caption": ${getLocaleContentBlockQuery('caption', locale)},
  },
  _type == 'gallery' => {
    _type,
    _key,
    photos[]{
      ${imageMeta(locale)}
    },
    hidden,
    shouldDecorateWithAngles,
    blockAngleDecorator
  },
  _type == 'timelineSection' => {
    _type,
    _key,
    "title": ${getLocaleQuery('title', locale)},
    timelineEvents[]{
      "title": ${getLocaleQuery('title', locale)},
      "content": ${getLocaleContentBlockQuery('content', locale)}
    },
    photo{
      ${imageMeta(locale)}
    }
  },
  _type == 'retailersList' => {
    _type,
    _key,
    featuredOnly,
    "retailers": *[_type == "retailer"]{
      _id,
      name,
      logo{
        ${imageMeta(locale)}
      },
      address[]{
        ${ptContent(locale)}
      },
      featured,
      website
    }
  },
  _type == 'pressList' => {
    _type,
    _key,
    featuredOnly,
    "press": *[_type == "press"]{
      _id,
      name,
      logo{
        ${imageMeta(locale)}
      },
      address[]{
        ${ptContent(locale)}
      },
      featured,
      website,
      excerpts[]{
        date,
        url,
        "description": ${getLocaleQuery('description', locale)}
      }
    }
  },
  _type == 'dividerPhoto' => {
    _type,
    _key,
    showFullsize,
    photo{
      ${imageMeta(locale)}
    }
  },
  _type == 'photo' => {
    _type,
    _key,
    photo{
      ${imageMeta(locale)}
    }
  },
  _type == 'productHero' => {
    _type,
    _key,
  },
  _type == 'collectionGrid' => {
    _type,
    _key,
  },
  _type == 'postsGrid' => {
    _type,
    _key,
    layout
  },
  _type == 'paragraph' => {
    _type,
    _key,
    "content": ${getLocaleContentBlockQuery('content', locale)},
    hidden,
    shouldDecorateWithAngles,
    blockAngleDecorator
  },
  _type == 'sheetOfPaper' => {
    _type,
    _key,
    "content": ${getLocaleContentBlockQuery('content', locale)},
    hidden
  },
  _type == 'collectionPreview' => {
    _type,
    _key,
    collection->{
      slug,
      products[@->isDraft != true && @->visible == true && @->wasDeleted != true]->${productSummary(
        locale
      )}
    },
    maxAmount,
    layout
  },
  _type == 'sideBySideImageWithContent' => {
    _type,
    _key,
    photo{
      ${imageMeta(locale)}
    },
    "content": ${getLocaleContentBlockQuery('content', locale)},
    itemAlignment,
    widerImage,
    reverseOrder,
    hidden,
    shouldDecorateWithAngles,
    blockAngleDecorator
  },
  _type == 'autoColumns' => {
    _type,
    _key,
    "title": ${getLocaleQuery('title', locale)},
    hideTitle,
    columns[]{
      justify,
      align,
      readableWidth,
      "content": ${getLocaleContentBlockQuery('content', locale)}
    },
    shouldDecorateWithAngles,
    blockAngleDecorator
  },
  _type == 'quote' => {
    _type,
    _key,
    "quote": ${getLocaleContentBlockQuery('quote', locale)},
    shouldDecorateWithAngles,
    blockAngleDecorator
  },
  _type == 'brandProfile' => {
    _type,
    _key,
    brand->{
      name,
      "description": ${getLocaleContentBlockQuery('description', locale)},
      "location":  ${getLocaleQuery('location', locale)},
      websiteUrl,
      instagramHandle,
      instagramHandleInternational,
      keyImages[]{
        ${imageMeta(locale)}
      },
      profilePage->{
        "slug": slug.current
      },
      collectionPage->{
        "slug": slug.current
      },
      "productFocus": ${getLocaleQuery('productFocus', locale)},
      designers[]->{
        "name": ${getLocaleQuery('name', locale)},
        profilePhoto{
          ${imageMeta(locale)}
        }
      }
    },
    emphasise,
    showProfileLink,
    showCollectionLink,
    shouldDecorateWithAngles,
    blockAngleDecorator
  },
  _type == 'brandCta' => {
    _type,
    _key,
    brand->{
      name,
      instagramHandle,
      instagramHandleInternational,
      profilePage->{
        "slug": slug.current
      },
      collectionPage->{
        "slug": slug.current
      }
    },
    showProfileLink,
    showCollectionLink,
    showInstagram,
  },
  _type == 'squares' => {
    _type,
    _key,
    "title": ${getLocaleQuery('title', locale)},
    hideTitle,
    narrow,
    squares[]{
      _type == 'squareContent' => {
        _type,
        _key,
        itemAlignment,
        theme,
        "content": ${getLocaleContentBlockQuery('content', locale)},
        "caption": ${getLocaleQuery('caption', locale)},
        link{
          ${link(locale)}
        }
      },
      _type == 'squareImage' => {
        _type,
        _key,
        theme,
        emphasise,
        photo{
          ${imageMeta(locale)}
        },
        "caption": ${getLocaleQuery('caption', locale)},
        "details": ${getLocaleContentBlockQuery('details', locale)},
        link{
          ${link(locale)}
        }
      },
      _type == 'squareProduct' => {
        _type,
        _id,
        _key,
        "product": *[_type == "product" && _id == ^ ._ref][0]${product(locale)},
        "caption": ${getLocaleQuery('caption', locale)}
      },
    }
  }
`
}

export interface SanityProduct {
  comparePrice: number
  description: any[]
  dimensions: any[]
  globalProductInfo: any[]
  id: number
  inStock: boolean
  klaviyoAccountID: string
  lowStock: boolean
  manufacturedIn: string
  materials: any[]
  optionSettings: string
  options: []
  photos: { listing: any[]; main: any[] }
  price: 13000
  sharedProductInfo: string
  shortDescription: any[]
  slug: string
  surfaceOption: any
  title: string
  vendor: string
  productCategory: string
  useGallery: boolean
  variants: any[]
  visible: boolean
  seo: {
    metaDesc?: string
    metaTitle?: string
    noIndex?: boolean
    shareGraphic?: any
  }
}

// All Products
export const allProducts = ({
  preview,
  locale,
}: {
  preview?: any
  locale: string
}) => `
  *[_type == "product"  && ${isActiveProduct}${
  preview?.active ? ' && _id in path("drafts.**")' : ''
}]${product(locale)} | order(title asc)
`

export interface SiteHeader {
  layout: 'default' | 'header-left-logo'
  menuDesktopLeft: { items: any[] }
  menuDesktopRight: { items: any[] }
  menuMobilePrimary: { items: any[] }
  menuMobileSecondary: { items: any[] }
  promo: PromoBarProps
}

export interface SiteSettings {
  cart: {
    message: string
    storeURL: string
  }
  cookieConsent: { link?: string; message?: string }
  footer: { blocks: any[] }
  header: SiteHeader
  productCounts: [{ count: number; slug: string }]
  seo: {
    metaDesc?: string
    metaTitle: string
    shareGraphic?: { _type: 'image'; asset: any }
    siteTitle: any
    noIndex: boolean
  }
}
// Construct our "site" GROQ
export const site = (locale) => {
  return `
  "site": {
    "cart": *[_type == "cartSettings"][0]{
      storeURL,
      "message": ${getLocaleQuery('messageLocale', locale)}
    },
    "productCounts": [ {"slug": "all", "count": count(*[_type == "product" && ${isActiveProduct}])} ] + *[_type == "collection"]{
      "slug": slug.current,
      "count": count(products)
    },
    "cookieConsent": *[_type == "cookieSettings"][0]{
      message,
      "link": link->{"type": _type, "slug": slug.current}
    },
    "header": *[_type == "headerSettings"][0]{
      layout,
      "promo": *[_type == "promoSettings"][0]{
        display,
        "text": ${getLocaleQuery('textLocale', locale)},
        "link": link->{"type": _type, "slug": slug.current}
      },
      menuDesktopLeft->{
        items[]{
          ${link(locale)},
          dropdownItems[]{
            ${link(locale)}
          },
          featured[]->${product(locale)}
        }
      },
      menuDesktopRight->{
        items[]{
          ${link(locale)},
          dropdownItems[]{
            ${link(locale)}
          },
          featured[]->${product(locale)}
        }
      },
      menuMobilePrimary->{
        items[]{
          ${link(locale)},
          dropdownItems[]{
            ${link(locale)}
          },
        }
      },
      menuMobileSecondary->{
        items[]{
          ${link(locale)},
          dropdownItems[]{
            ${link(locale)}
          },
        }
      }
    },
    "footer": *[_type == "footerSettings"][0]{
      "blocks": [
        {
          "title": ${getLocaleQuery(
            'blockTitle1Locale',
            locale,
            'blockTitle1'
          )},
          newsletter{
            "id": "footer",
            klaviyoListID,
            "submit": ${getLocaleQuery('submitLocale', locale)},
            "successMsg": ${getLocaleContentBlockQuery(
              'successMsgLocale',
              locale
            )},
            "errorMsg": ${getLocaleContentBlockQuery('errorMsgLocale', locale)},
            "terms": ${getLocaleQuery('termsLocale', locale)},
          }
        },
        {
          "title": ${getLocaleQuery(
            'blockTitle2Locale',
            locale,
            'blockTitle1'
          )},
          "menu": blockMenu2->{
            items[]{
              ${link(locale)}
            }
          }
        },
        {
          "title": ${getLocaleQuery(
            'blockTitle3Locale',
            locale,
            'blockTitle1'
          )},
          "menu": blockMenu3->{
            items[]{
              ${link(locale)}
            }
          }
        },
        {
          "title": ${getLocaleQuery(
            'blockTitle4Locale',
            locale,
            'blockTitle1'
          )},
          social[]{
            icon,
            url
          }
        }
      ]
    },
    "seo": *[_type == "seoSettings"][0]{
      "siteTitle": ${getLocaleQuery('siteTitleLocale', locale)},
      "metaTitle": ${getLocaleQuery('metaTitleLocale', locale)},
      "metaDesc": ${getLocaleQuery('metaDescLocale', locale)},
      shareGraphic,
    },
  }
`
}

/*  ------------------------------ */
/*  Sanity API Functions
/*  ------------------------------ */

// Fetch all dynamic docs
export async function getAllDocSlugs(doc) {
  const data = await getSanityClient().fetch(
    `*[_type == "${doc}" && !(_id in path("drafts.**"))]{ "slug": slug.current }`
  )
  return data
}

export async function getAllProductSlugs() {
  const data = await getSanityClient().fetch(
    `*[_type == "product" && ${isActiveProduct}]{ "slug": slug.current }`
  )
  return data
}

// Fetch all our page redirects
export async function getAllRedirects() {
  const data = await getSanityClient().fetch(
    `*[_type == "redirect"]{ from, to }`
  )
  return data
}

// Fetch a static page with our global data
export async function getStaticPage({ pageData, preview, locale }) {
  const query = `
  {
    "page": ${pageData},
    ${site(locale)}
  }
  `

  const data = await getSanityClient(preview).fetch(query)

  return data
}

// All Products
export const allPosts = ({
  preview,
  locale,
}: {
  preview?: any
  locale: string
}) => `
  *[_type == "post" ${
    preview?.active ? ' && _id in path("drafts.**")' : ''
  }] | order(_updatedAt desc) {
    _updatedAt,
    _createdAt,
    "slug": slug.current,
    photo{${imageMeta(locale)}},
    "title": ${getLocaleQuery('title', locale)},
    "shortDescription": ${getLocaleContentBlockQuery(
      'shortDescription',
      locale
    )},
  }
`

export async function getRetailers({ preview, locale }) {
  const query = `
    {
      "retailers": *[_type == "retailer"] | order(_updatedAt desc){
        _id,
        name,
        logo{
          ${imageMeta(locale)}
        },
        address,
        website
      },
      ${site(locale)}
    }
    `

  const data = await getSanityClient(preview).fetch(query)

  return data
}

// Fetch a specific dynamic page with our global data
export async function getBlogPost({ slug, preview, locale }) {
  const slugs = [`/${slug}`, slug, `/${slug}/`]

  const query = `
    {
      "page": *[_type == "post" && slug.current in ${JSON.stringify(
        slugs
      )}] | order(_updatedAt desc)[0]{
        _createdAt,
        _updatedAt,
        "slug": slug.current,
        "title": ${getLocaleQuery('title', locale)},
        modules[]{
          ${modules(locale)}
        },
        seo{
          ${pageSeo(locale)}
        }
      },
      ${site(locale)}
    }
    `

  const data = await getSanityClient(preview).fetch(query)

  return data
}
// Fetch a specific dynamic page with our global data
export async function getPage({ slug, preview, locale }) {
  const slugs = [`/${slug}`, slug, `/${slug}/`]

  const query = `
    {
      "page": *[_type == "page" && slug.current in ${JSON.stringify(
        slugs
      )}] | order(_updatedAt desc)[0]{
        _updatedAt,
        _createdAt,
        "title": ${getLocaleQuery('titleLocale', locale)},
        hasTransparentHeader,
        modules[]{
          ${modules(locale)}
        },
        seo{
          ${pageSeo(locale)}
        }
      },
      ${site(locale)}
    }
    `

  const data = await getSanityClient(preview).fetch(query)

  return data
}

// Fetch a specific product with our global data
export async function getProduct({ slug, preview, locale }) {
  const query = `
    {
      "page": *[_type == "product" && slug.current == "${slug}" && ${isActiveProduct}] | order(_updatedAt desc)[0]{
        hasTransparentHeader,
        modules[]{
          ${modules(locale)}
        },
        "product": ${product(locale)},
        seo{
          ${pageSeo(locale)}
        }
      },
      ${site(locale)}
    }
  `

  const data = await getSanityClient(preview).fetch(query)

  return data
}

// Fetch a specific collection with our global data
export async function getCollection({ slug, preview, locale }) {
  const query = `
    {
      "page": *[_type == "collection" && slug.current == "${slug}"] | order(_updatedAt desc)[0]{
        hasTransparentHeader,
        modules[]{
          ${modules(locale)}
        },
        products[@->isDraft != true && @->visible == true && @->wasDeleted != true]->${product(
          locale
        )},
        seo{
          ${pageSeo(locale)}
        }
      },
      ${site(locale)}
    }
  `

  const data = await getSanityClient(preview).fetch(query)

  return data
}

// SendGrid Post function, used by our API route (so we don't expose our API key)
export async function postEmail(apiKey, data) {
  const {
    formName = 'Contact Form',
    fromAddress,
    notificationEmails,
    templateID,
    name,
    email,
    subject,
    message,
  } = data

  const toAddresses = notificationEmails.map((email) => ({
    email: email,
  }))

  const options = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${apiKey}`,
    },
    data: {
      personalizations: [
        {
          to: toAddresses,
          subject: subject,
          dynamic_template_data: {
            formName: formName,
            name: name,
            email: email,
            subject: subject,
            message: message,
          },
        },
      ],
      from: {
        email: fromAddress,
      },
      template_id: templateID,
    },
    url: 'https://api.sendgrid.com/v3/mail/send',
  }

  //@ts-ignore
  const post = await axios(options)
    .then((response) => {
      console.log('SendGrid Success')
      return response
    })
    .catch((err) => {
      console.log('SendGrid Failed')
      return err.response
    })

  return post
}
